var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('app-header'),_c('nav-bar'),_c('div',{staticClass:"content"},[_c('overlay-loader',{attrs:{"loading":_vm.loading}}),(_vm.alert)?_c('v-alert',{attrs:{"type":_vm.typeAlert,"transition":"scale-transition"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card',{staticClass:"card-library-content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"col-md-4 d-flex flex-column justify-content-center text-center"}),_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"2","md":"2"}},[_c('v-select',{attrs:{"items":_vm.filters,"label":"Filter","prepend-inner-icon":"mdi-filter-variant","item-text":"headerText","item-value":"headerValue","multiple":"","underlined":""},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})],1),_c('v-col',[(_vm.filterValues.length > 0)?_c('study-filter',{attrs:{"studyNameList":_vm.filterValues},on:{"selectedStudy":_vm.sendStudyValueToParent}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"8","sm":"2","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","underlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('download-documents',{attrs:{"documents":_vm.filterValues}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"sort-by":['upload_date_format'],"sort-desc":true,"headers":_vm.headers,"items":_vm.filterValues,"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"item.required_verified_copy",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.required_verified_copy}})]}},{key:"item.exists_qi",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.exists_qi}})]}},{key:"item.is_amendment",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.is_amendment}})]}},{key:"item.isf",fn:function(ref){
var item = ref.item;
return [_c('boolean-label',{attrs:{"active":item.isf}})]}},{key:"item.submit_for_approval_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.submit_for_approval_format)))])]}},{key:"item.doc_date_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.doc_date_format)))])]}},{key:"item.received_date_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.received_date_format)))])]}},{key:"item.upload_date_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.upload_date_format)))])]}},{key:"item.last_modified_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.last_modified_format)))])]}},{key:"item.approval_date_format",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.approval_date_format).split(',')[0]))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('router-link',{staticClass:"tab-router-link",attrs:{"to":'/library/edit/'+item.id+'/'}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.docExpired === true)?_c('v-subheader',[_vm._v("Expired")]):_vm._e(),_c('router-link',{staticClass:"tab-router-link",attrs:{"to":'/library/edit/'+item.id}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"item.deadline_format",fn:function(ref){
var item = ref.item;
return [(item.is_urgent && item.status_id !== _vm.Constants.STATUS_APPROVED_ID
                  && item.deltaTime < 1)?_c('v-chip',{staticClass:"red lighten-3"},[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])]):(item.status_id === _vm.Constants.STATUS_APPROVED_ID)?_c('v-chip',{staticClass:"blue lighten-3"},[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])]):(item.is_urgent && item.status_id !== _vm.Constants.STATUS_APPROVED_ID
                  && item.deltaTime < 2)?_c('v-chip',{staticClass:"orange lighten-3"},[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])]):(!item.is_urgent && item.status_id !== _vm.Constants.STATUS_APPROVED_ID
                  && item.deltaTime <= 3)?_c('v-chip',{staticClass:"red lighten-3"},[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])]):(!item.is_urgent
                  && item.status_id !== _vm.Constants.STATUS_APPROVED_ID && item.deltaTime <= 6)?_c('v-chip',{staticClass:"orange lighten-3"},[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])]):_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm.parseDateToString(item.deadline_format).split(',')[0]))])])]}},{key:"item.status_name",fn:function(ref){
                  var item = ref.item;
return [_c('data-status-label',{attrs:{"status_id":item.status_id}})]}},(_vm.roleManager.isAnyAdmin())?{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)]}}:null,{key:"item.owner",fn:function(ref){
                  var item = ref.item;
return [(item.owner && item.owner.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.owner))])]}},{key:"item.approver_user_email",fn:function(ref){
                  var item = ref.item;
return [(item.approver_user_email && item.approver_user_email.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.approver_user_email))])]}},{key:"item.last_user_email",fn:function(ref){
                  var item = ref.item;
return [(item.last_user_email && item.last_user_email.includes('@anonymize.com'))?_c('span',[_c('i',[_vm._v("User deleted")])]):_c('span',[_vm._v(_vm._s(item.last_user_email))])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900","retain-focus":false},model:{value:(_vm.deleteDocumentDialog),callback:function ($$v) {_vm.deleteDocumentDialog=$$v},expression:"deleteDocumentDialog"}},[_c('v-card',{staticClass:"dialog-style"},[_c('v-card-title',{staticClass:"tfe-black-color"},[_c('strong',[_vm._v("Are you sure to delete this document?")])]),_c('v-card-text',[_c('p',{staticClass:"tfe-black-color"},[_c('strong',[_vm._v("Document")]),_vm._v(": #"+_vm._s(_vm.deleteFileItem.id)+" "+_vm._s(_vm.deleteFileItem.title)+" ")]),_c('v-text-field',{attrs:{"label":"Add a commentary*","underlined":"","clearable":"","required":""},model:{value:(_vm.deleteDocumentComment),callback:function ($$v) {_vm.deleteDocumentComment=$$v},expression:"deleteDocumentComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"error"},on:{"click":function($event){_vm.deleteDocumentDialog=false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.deleteDocumentComment === '' || _vm.deleteDocumentComment.length < 1},on:{"click":function($event){return _vm.deleteDocument()}}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }