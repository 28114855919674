<template>
  <div>
    <overlay-loader :loading="loading"></overlay-loader>
    <v-row id="row-download-documents" justify="end">
        <v-col id="col-download-documents" class="text-righ">
            <v-btn
            outlined
            block
            rounded
            id="export-Studies-btn"
            @click="exportStudiesFile()"
            style="width:100%">
              <v-icon class="mr-2">mdi-file-download-outline</v-icon>
              Download table
            </v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script>
// import * as Excel from 'exceljs/dist/exceljs';
// import { saveAs } from 'file-saver';
import excelManager from '@/store/excelManager';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';

export default {
  name: 'DownloadDocuments',
  components: {
    OverlayLoader,
  },
  props: {
    documents: Array,
  },
  computed: {
    tableFiltered() {
      return this.documents;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    exportStudiesFile() {
      const columns = [
        // eslint-disable-next-line
        { header: 'Title', key: 'title', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Study', key: 'study_name', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Zone', key: 'zone_name', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Section', key: 'section_name', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Artifact', key: 'artifact_name', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Owner', key: 'owner', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Status', key: 'status_name', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Last User', key: 'last_user_email', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Upload Date', key: 'upload_date_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Submit for Approval Date', key: 'submit_for_approval_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Last Modified Date', key: 'last_modified_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Doc Date', key: 'doc_date_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Deadline', key: 'deadline_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Approval Date', key: 'approval_date_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Received Date', key: 'received_date_format', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'isf', key: 'isf', width: 30, filterButton: true },
        // eslint-disable-next-line
        { header: 'Required Verified Copy', key: 'required_verified_copy', width: 30, filterButton: true },
      ];
      excelManager.exportExcel(this.tableFiltered, columns, 'LibraryReport');
    },
  },
};
</script>
